var U = require("../utils/ajax.js");

var Create = function(opt) {
	U.ajax({
		service: 'paycenter.create',
		data: {
			order_id: opt.data.order_id,
			payment: opt.data.payment,
			usedeposit:opt.data.usedeposit
		},
		succ: function(res, all) {
			opt.succ(res, all);
			/* if (res.payment.package) {
			   if (res.payment.paySign) {
			     wx.requestPayment({
			       timeStamp: res.payment.timeStamp,
			       nonceStr: res.payment.nonceStr,
			       package: res.payment.package,
			       signType: res.payment.signType,
			       paySign: res.payment.paySign,
			       success: function (t) {
			         U.showtip("支付成功");
			         opt.succ(t);
			       },
			       fail: function (t) {
			         if (t.errMsg == 'requestPayment:fail cancel') {
			           U.showtip("微信支付取消", function () {}, 'none');
			         } else {
			           U.showtip("微信支付失败:" + t, errMsg, function () {}, 'none');
			         }

			         opt.fail(t);
			       },
			       complete: function () {}
			     });
			   } else {
			     U.showtip("获取微信支付数据失败");
			   }
			 } else {
			   U.showError(all.msg);
			 } */
		},
		fail: function(res, all) {
			opt.fail(res, all);
			// U.showError(all.msg);
		}
	});
};
var payMethod = function(opt) {
	U.ajax({
		service: 'paycenter.payMethod',
		data: {

		},
		succ: function(res, all) {
			opt.succ(res, all);
		},
		fail: function(res, all) {
			opt.fail(res, all);
		}
	});
};
var wxPay = function(opt) {
	if (opt.wxpayParams.package) {
		if (opt.wxpayParams.paySign) {
			wx.requestPayment({
				timeStamp: opt.wxpayParams.timeStamp,
				nonceStr: opt.wxpayParams.nonceStr,
				package: opt.wxpayParams.package,
				signType: opt.wxpayParams.signType,
				paySign: opt.wxpayParams.paySign,
				success: function(t) {
					U.showtip("支付成功");
					opt.succ(t);
				},
				fail: function(t) {
					console.log('t',t);
					if (t.errMsg == 'requestPayment:fail cancel') {
						U.showtip("微信支付取消", function() {}, 'none');
					} else {
						U.showtip("微信支付失败:" + t, errMsg, function() {}, 'none');
					}

					opt.fail(t);
				},
				complete: function() {}
			});
		} else {
			U.showtip("获取微信支付数据失败");
		}
	} else {
		U.showtip(opt.msg);
	}
};
var paySuccess = function(opt) {
	U.ajax({
		service: 'paycenter.paySuccess',
		data: {
			payment_id:opt.data.payment_id
		},
		succ: function(res, all) {
			opt.succ(res, all);
		},
		fail: function(res, all) {
			opt.fail(res, all);
		}
	});
};
var replacePay = function(opt) {
	U.ajax({
		service: 'paycenter.replacePay',
		data: {
			order_id:opt.data.order_id
		},
		succ: function(res, all) {
			opt.succ(res, all);
		},
		fail: function(res, all) {
			opt.fail(res, all);
		}
	});
};
module.exports = {
	Create: Create,
	payMethod: payMethod,
	wxPay: wxPay,
	paySuccess:paySuccess,
	replacePay:replacePay,
};
