<template>
  <div class="paycenter">
    <div class="container">
      <div class="paybox">
        <payment-info class="paymentinfo" :order_detail="order_detail"></payment-info>
        <pay-main class="paymain" :userInfo="userInfo" :orderList="orderList" :order_detail="order_detail"></pay-main>
      </div>
    </div>
  </div>
</template>
<script>
import { PayMain, PaymentInfo } from "./child";
import User from '@/model/User.js'
import Paycenter from '@/model/Paycenter.js'
export default {
  name: "paycenter",
  data() {
    return {
      //用户信息
      userInfo:{},

      //用户订单信息
      order_detail:{},

      //支付列表
      orderList:[]
    };
  },
  created() {
    this.init()
  },
  components: {
    PayMain,
    PaymentInfo,
  },

  methods:{
    init(){
      //将浏览器中的selgoods删除
      localStorage.removeItem('selgoods')
      //重新请求购物车数据
      this.$store.dispatch("getCart");

      this.getUserInfo()
      this.getUserOrderInfo()
      this.getOrderMethod()
    },

    //获取用户信息
    getUserInfo(){
      User.Info.Detail({
        data:{
          notredirect: 'undefined'
        },
        succ:(res)=>{
          this.userInfo = res.userinfo
        },
        fail:(res,all)=>{
          this.$message('error',all.msg)
        },
        
      })

    },

     //获取用户订单信息
    getUserOrderInfo(){
      User.Order.Detail({
          data:{
         order_id:this.$route.query.order_id,
         frompaycenter:'1'
        },
        succ:(res)=>{
        this.order_detail = res.order_detail
        },
        fail:()=>{},
        
      })

    },


    //获取支付方式
    getOrderMethod(){
      Paycenter.payMethod({
        data:{
        },
        succ:(res)=>{
          this.orderList = res.list
        },
        fail:()=>{},
        
      })
    }


  }
};
</script>
<style lang='scss' scoped>


.paycenter {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  background-color: white;
  .container {
    border: 1px solid var(--border-color-default);
    .paybox {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      width: 75%;
      margin: 0 auto;

      .paymentinfo {
        margin-bottom: 2.5rem;
      }
    }
  }
}
</style>