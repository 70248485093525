import { render, staticRenderFns } from "./MyCheckbox.vue?vue&type=template&id=6e49dd12&scoped=true&"
import script from "./MyCheckbox.vue?vue&type=script&lang=js&"
export * from "./MyCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./MyCheckbox.vue?vue&type=style&index=0&id=6e49dd12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e49dd12",
  null
  
)

export default component.exports