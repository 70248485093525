<template>
  <div class="mycheckbox">
    <input
      type="checkbox"
      class="mycheck"
      :disabled="disabled"
      v-model="model"
      :value="label"
    >
    <i
      class="iconfont icon-yigouxuan"
      v-if="isactive && !disabled"
      :style="{ color: color.active }"
    ></i>
    <i
      class="iconfont icon-weigouxuan"
      v-if="!isactive && !disabled"
      :style="{ color: color.unactive }"
    ></i>

        <i class="iconfont icon-jinyong1 jinyong" v-if="disabled"></i>
        <slot></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    value: null,
    label: [String, Number, Object],
    isGroup: {
      type: Boolean,
      default: true,
    },
    color: {
      type: Object,
      default: () => {
        return { active: "#dc3545", unactive: "#6c757d" };
      },
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    isactive() {
      if (this.isGroup) {
        return this.value.includes(this.label);
      } else {
        return this.model;
      }
    },
  },
  created() {},
};
</script>
<style lang='scss' scoped>


.mycheckbox {
  position: relative;
  display: flex;
  align-items: center;
  .mycheck {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
  }
}

i {
  font-size: 1rem;
}
.jinyong{
    font-size:1.15rem;
    color:#dc3545;
}

</style>