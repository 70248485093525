<template>
  <div class="paymain">
    <div class="item">
      <div class="gx">
        <my-checkbox
          v-model="isUseYe"
          :isGroup="false"
          :disabled="isDisabled"
        ></my-checkbox>
      </div>
      <div class="img">
        <img
          :src="require('@/assets/img/paycenter/balance.jpg')"
          alt=""
        />
      </div>
      <div class="name">{{$t('paycenter.Common.BalancePaid')}}</div>
      <div class="decripe">
        <div class="lookInfoBtn">{{$t('paycenter.Common.CurrentBalance')}}：{{ userInfo.balance_text }}</div>
      </div>
    </div>

    <div class="item" v-for="(item, index) in orderList" :key="item.pay_app_id">
      <div class="gx" @click="choosePay(index)">
        <my-radio
          v-model="choose"
          :label="item.pay_app_id"
          ref="radio"
        ></my-radio>
      </div>
      <div class="img">
        <img v-lazy="item.logo" />
      </div>
      <div class="name">{{ item.name }}</div>
      <div class="decripe">
        <div class="lookInfoBtn" @click="isshow = !isshow">{{$t('paycenter.Common.PaymentInstructions')}}</div>
        <pop-up :isshow="isshow">
          <div class="HiddenContent">
            <div class="cont" v-html="item.intro"></div>
            <div class="closeBtn" @click="isshow = !isshow">{{$t('Common.Button.Close')}}</div>
          </div>
        </pop-up>
        <div class="brief">{{ item.brief }}</div>
      </div>
    </div>
    <div class="btnbox">
      <div class="count" v-if="isUseYe">
        {{$t('paycenter.Msg.Pay')}}<strong class="newPrice">{{ newPrice.price }}</strong>
      </div>
      <div class="btn" @click="payment"><span>{{$t('paycenter.Common.Paynow')}}</span></div>
    </div>
  </div>
</template>
<script>
import MyRadio from "common/radio/Radio";
import MyCheckbox from "common/checkbox/MyCheckbox";
import PopUp from "common/popup/PopUp";
import Paycenter from "@/model/Paycenter.js";
export default {
  name: "paymain",
  data() {
    return {
      //选择的支付方式
      choose: "",
      //是否使用余额支付
      isUseYe: false,
      //是否显示弹框
      isshow: false,
    };
  },
  created() {},
  props: {
    userInfo: {
      type: Object,
    },
    orderList: {
      type: Array,
    },
    order_detail: {
      type: Object,
    },
  },
  computed: {
    //是否禁用余额支付
    isDisabled() {
      if(!this.userInfo.balance){
        return true
      }
      return Number.parseFloat(this.userInfo.balance) * 100 == 0;
    },
    newPrice() {
      // 用户余额
      let balance = Number.parseFloat(this.userInfo.balance);
      //商品总价
      let total = Number.parseFloat(this.order_detail.remain_needpay_amount);
      //倍数
      let multiple = 10000000;
      if (balance >= total) {
        return {
          price: "£00.00",
          isenough: true,
        };
      } else {
        return {
          price: "£" + ((total * multiple - balance * multiple) / multiple).toFixed(2),
          isenough: false,
        };
      }
    },
  },
  components: {
    MyRadio,
    MyCheckbox,
    PopUp,
  },
  methods: {
    //   选择了一个支付方式
    choosePay(index) {
      this.$refs.radio[index].clickRadio();
    },

    //点击支付按钮
    payment() {
      //是否使用余额
      if (this.isUseYe) {
        //余额是否足够
        if (this.newPrice.isenough) {
          //足够 网络 用余额
          this.gopayment(1);
        } else {
          //不足够,判断当前是否选了其他支付方式
          if (this.choose == "") {
            //没有
            this.$message('error','余额不足，请添加其他支付方式!',this,2000)
     
          } else {
            //网络请求  用余额 + 其他
            this.gopayment(2);
          }
        }
      } else {
        //网络请求  不用余额 + 用其他
        this.gopayment(3);
      }
    },

    //发起支付
    gopayment(type) {
      let payment;
      if (type == 1) {
        payment = {pay_type:"balance"};
      } else if (type == 2) {
        payment = { pay_type: this.choose };
      } else if (type == 3) {
        payment = { pay_type: this.choose };
      }

      Paycenter.Create({
        data: {
          order_id: this.$route.query.order_id,
          payment: JSON.stringify(payment),
          usedeposit: this.isUseYe,
        },
        succ: (res,all) => {
     
          //全部使用余额支付
          if(payment.pay_type=='balance'){
            this.$message('success',all.msg,this,2000)
            setTimeout(()=>{
              this.$router.replace('/member/orders')
            },1000)
          }else{
            location.href=res.payurl2
          }
        },
        fail: (res,all) => {
          this.$message('error',all.msg,this,2000)
        },
        
      });
    },
  },
};
</script>
<style lang='scss' scoped>

.paymain {
  .item {
    display: flex;
    font-size: 0.875rem;
    padding: 0.875rem 0;
    border-bottom: 1px solid var(--border-color-default);
    .gx {
      margin-right: 1.25rem;
    }
    .img {
      width: 3.25rem;
      height: 3.25rem;
      margin-right: 1.25rem;
      border-radius: 0.2rem;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .name {
      color: var(--text-color-title);
      font-weight: 600;
      margin-right: 1.25rem;
    }

    .decripe {
      color: var(--text-color-title);
      .lookInfoBtn {
        cursor: pointer;
        &:hover {
          opacity: 0.9;
        }
      }
      .brief {
        color: var(--text-color-minor);
        max-width: 20rem;
      }
    }
  }
  .btnbox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.875rem;
    .count {
      font-size: 0.875rem;
      color: var(--text-color-title);
      margin-right: 1rem;

      .newPrice {
        color: var(--red-color);
        margin-left: 0.5rem;
      }
    }

    .btn {
      padding: 0.5rem 1.25rem;
      background-color: var(--red-color);
      display: inline-block;
      border-radius: 0.2rem;

      cursor: pointer;
      span {
        color: white;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.HiddenContent {
  padding: 1rem;
  .cont {
    max-height: 40vh;
    overflow: auto;
  }

  .closeBtn {
    margin-top: 0.75rem;
    width: 4rem;
    height: 1.5rem;
    background-color: var(--red-color);
    cursor: pointer;
    color: white;
    border-radius: 0.3rem;
    text-align: center;
    line-height: 1.5rem;
    font-size: 0.75rem;
    margin-left: auto;
    margin-right: auto;
    &:hover {
      opacity: 0.9;
    }
  }
}
</style>