<template>
  <div class="payment">
    <div class="box">
      <div class="left">
        <h4 class="successHit">
          {{$t('paycenter.Msg.OrderSendSucc')}}{{$t('Paycenter.Pages.OrderId')}}：{{ $route.query.order_id }}
        </h4>
        <!-- <div class="time">
          {{$t('paycenter.Msg.PleaseIn')}}
          <span
            >{{ time.d }} {{$t('Common.NavTitle.Day')}} {{ time.h }} {{$t('Common.NavTitle.Hour')}} {{ time.m }} {{$t('Common.NavTitle.Minute')}}
            {{ time.s }} {{$t('Common.NavTitle.Second')}} </span
          >{{$t('paycenter.Msg.FinshPay')}}
          <timer :deadline="deadline" :time.sync="time"></timer>
        </div> -->
      </div>

      <div class="right">
        <h2 class="price">
          {{$t('User.Orders.Pages.AccountPayable')}}<strong>{{ order_detail.remain_needpay_amount_text }}</strong>
        </h2>
        <p class="order" @click="isopenOrder = !isopenOrder">
          {{$t('Common.NavTitle.OrderDetail')}}<i class="iconfont icon-jiantou-copy-copy"></i>
        </p>
      </div>
    </div>

    <div class="hidden" v-show="isopenOrder">
      <div class="userInfo">
        <span class="location">{{$t('User.Address.Addr')}}：</span>
        <span>{{ order_detail.ship_addr }}</span>
        <span class="name">{{$t('User.Address.Name')}}：</span>
        <span>{{ order_detail.ship_name }}</span>
        <span class="mobile">
          {{ order_detail.ship_mobile }}
        </span>
      </div>
      <div class="buyInof">
        <p class="goodNames">
         {{$t('paycenter.Common.Description')}}：
          <span
            class="gooditem"
            v-for="item in order_detail.goods_list"
            :key="item.detail_id"
            >{{ item.name }} * {{ item.num }}</span
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Timer from "common/time/Timer";
export default {
  data() {
    return {
      //结束时间
      deadline: "2021/12/27 19:00:00",
      //当前显示的时间
      time: { d: "00", h: "00", m: "00", s: "00" },

      //是否打开订单详情
      isopenOrder: false,
    };
  },
  created() {},
  props: {
    order_detail: {
      type: Object,
    },
  },

  components: {
    Timer,
  },
};
</script>
<style lang='scss' scoped>

.payment {
  .box {
    display: flex;
    justify-content: space-between;

    .left {
      max-width: 60%;
      .successHit {
        font-size: 0.875rem;
        color: var(--text-color-title);
        margin-bottom: 0.3rem;
      }

      .time {
        font-size: 0.75rem;
        color: var(--text-color-minor);
        span {
          color: var(--red-color);
        }
      }
    }

    .right {
       max-width: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .price {
        font-size: 0.875rem;
        margin-bottom: 0.3rem;
        strong {
          font-size: 1rem;
          color: var(--red-color);
          margin: 0 0.5rem;
        }
      }
      .order {
        cursor: pointer;
        font-size: 0.75rem;
        color: var(--minor-color);
        i {
          margin-left: 0.2rem;
        }
      }
    }
  }

  .hidden {
    width: 100%;
    margin-top: 0.875rem;
    border-top: 1px solid var(--border-color-default);
    border-bottom: 1px solid var(--border-color-default);
    padding: 0.75rem 0;
    .userInfo {
      margin-bottom: 0.5rem;
      font-size: 0.75rem;
      color: var(--text-color-minor);

      .location,
      .name {
        color: var(--text-color-title);
        margin-right: 0.3rem;
      }

      .mobile {
        margin-left: 0.5rem;
      }

      .name {
        margin-left: 0.5rem;
      }
    }
    .buyInof {
      .goodNames {
        font-size: 0.75rem;
        color: var(--text-color-title);
          span {
            line-height: 1.5;
          }
        .gooditem {
          color: var(--text-color-minor);
          margin-right: 0.5rem;
        }
      }
    }
  }
}
</style>