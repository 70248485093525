<template>
  <div class="radio">
    <input
      ref="input"
      class="myradio"
      :disabled = "disabled"
      :type="type"
      :value="label"
      v-model="innerVal"
    />
    <i class="iconfont icon-yigouxuan active" v-if="innerVal == label"></i>
    <i class="iconfont icon-weigouxuan" v-else></i>

  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  props: {
    value: null,
    label: {
      type: [String, Number, Boolean],
      defualt: "",
    },
    type: {
      type: String,
      default: "radio",
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  computed: {
    innerVal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    clickRadio() {
      if(this.innerVal == this.label){
        return this.$emit("input", '');
      }
      this.$refs.input.click();
    },
  },
};
</script>
<style lang='scss' scoped>


.radio {
  display: inline-block;
  position: relative;
}

.myradio {
  position: absolute;
  opacity: 0;
  outline: none;
  z-index: 2;
 top:0;
 left:0;
 width: 100%;
 height: 100%;
}

i {
  font-size: 1rem;
  color: #6e6e73;
}

.active {
  color: #dc3545;
}
</style>